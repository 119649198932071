<template>
  <section
    role="region"
    class="achieved-results-section"
    id="achieved-results"
    ref="achievedResultsSection"
  >
    <TheCard v-for="item in metricNumbers" :key="item.id">
      <template #out-of-box>
        <div class="outline-circle">
          <TheIcon
            :icon="`${item.mediaSrc}`"
            :size="36"
            :type="item.mediaType ?? ''"
          />
        </div>
      </template>
      <template #default>
        <h2 ref="increaseNumber">
          {{ item.prefix + item.startValue + item.sufix }}
        </h2>
        <h3>{{ item.text }}</h3>
      </template>
    </TheCard>
  </section>
</template>

<script setup lang="ts">
import { useResultsStore } from "@/stores/home/results";

const store = useResultsStore();
const metricNumbers = store.getMetricNumbers;

const achievedResultsSection = ref<any>(null);
const startedCount = ref<Boolean>(false);

const startCount = (dataItem: AutoNumberCardItem) => {
  let count = setInterval(() => {
    dataItem.startValue++;
    if (dataItem.startValue >= dataItem.value) {
      clearInterval(count);
    }
  }, 750 / dataItem.value);
};

const AchievedResultsWatcher = () => {
  var section = achievedResultsSection.value;
  var array = [...metricNumbers];
  if (
    window.scrollY + section.offsetHeight >=
    section.offsetTop - section.offsetHeight
  ) {
    if (!startedCount.value) {
      array.forEach((metric: AutoNumberCardItem) => startCount(metric));
    }
    startedCount.value = true;
    window.removeEventListener("scroll", AchievedResultsWatcher);
  }
};

onMounted(() => {
  window.addEventListener("scroll", AchievedResultsWatcher, { passive: true });
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", AchievedResultsWatcher);
});
</script>

<style lang="scss">
@import "@/styles/variables";

.achieved-results-section {
  max-width: none;
  background-image: url(@/assets/images/home/how-it-works/bg-square-mobile.png);
  background-repeat: repeat;
  background-position: center top;
  display: grid;
  row-gap: 61px;
  padding: 75px 20px 40px;
  border-top: 2px solid $blue;
  border-bottom: 2px solid $blue;

  .default-card {
    overflow: unset;
    justify-content: flex-end;
    width: 320px;
    height: 161px;
    border: 2px solid $blue;
    padding: 51px 0px 24px;

    .outline-circle {
      margin-top: -36px;
      background-color: $blue;
      color: $white;
      border-radius: 50%;
      width: 72px;
      height: 72px;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
    }

    h2,
    h3 {
      line-height: 110%;
    }

    h2 {
      font-size: 48px;
      margin: 12px 0px;
    }
  }

  @media screen and (min-width: 834px) and (max-width: 959px) {
    .default-card {
      width: 368px;
    }
  }

  @media screen and (min-width: 960px) and (max-width: 1199px) {
    column-gap: 34px;
  }

  @media screen and (min-width: 834px) {
    grid-template-columns: auto auto;
    row-gap: 73px;
  }

  @media screen and (min-width: 960px) {
    column-gap: 34px;
    justify-content: center;

    .default-card {
      width: 346px;
    }
  }

  @media screen and (min-width: 1200px) {
    column-gap: 170px;
    padding: 103px 0px 68px;
  }
}
</style>
