<template>
  <section role="banner" class="banner-section" id="banner">
    <div class="banner-content">
      <slot name="title">
        <h1 v-if="titleContent" class="banner-title">{{ titleContent }}</h1>
      </slot>
      <slot name="text">
        <h4 v-if="textContent" class="banner-text">
          {{ textContent }}
        </h4>
      </slot>
      <v-btn class="default-button banner-button" color="primary" type="button" @click="buttonClicked">
        {{ buttonText ?? 'Saiba mais' }}
      </v-btn>
    </div>
    <ThePicture :mobilePath="mobileImage" :tabletPath="tabletImage ? tabletImage : false" :desktopPath="desktopImage"
      :mobileQuerie="mobileQuerie ? mobileQuerie : '833px'" :desktopQuerie="desktopQuerie ? desktopQuerie : '834px'"
      :altText="imageAlt" />
  </section>
</template>

<script setup lang="ts">
import { VBtn } from 'vuetify/components'
const emit = defineEmits(["button-clicked"])

defineProps<{
  mobileImage: string,
  tabletImage?: string,
  desktopImage: string,
  imageAlt: string,
  titleContent?: string,
  textContent?: string,
  buttonText?: string,
  mobileQuerie?: string,
  desktopQuerie?: string,
}>()

const buttonClicked = () => emit("button-clicked");
</script>

<style lang="scss">
.banner-section {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  padding: 54px 20px 26px;
  text-align: center;

  .banner-content {

    .banner-title {
      margin-bottom: 24px;
    }

    .banner-text {
      max-width: 400px;
      margin: 0px auto 24px;
    }

    .banner-button {
      margin-bottom: 36px;
    }
  }

  .image-component img {
    width: 320px;
    height: 342px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  @media screen and (min-width: 834px) and (max-width: 959px) {
    justify-content: space-evenly;
    column-gap: 27px;

    .banner-content .banner-text {
      width: 372px;
    }

    .image-component img {
      width: 100%;
    }
  }

  @media screen and (min-width: 834px) and (max-width: 1199px) {
    padding: 34px 30px 36px;

    .banner-content {

      .banner-title {
        margin-bottom: 16px;
      }

      .banner-button {
        margin-bottom: 0px;
        padding: 15px 27px;
      }
    }

    .image-component img {
      width: 370px;
      height: 396px;
      vertical-align: text-top;
    }
  }

  @media screen and (min-width: 960px) and (max-width: 1199px) {
    column-gap: 77px;
  }

  @media screen and (min-width: 834px) {
    align-items: center;
    flex-direction: row;
    text-align: start;

    .banner-content {
      .banner-text {
        margin: 0px 0px 24px;
      }
    }
  }

  @media screen and (min-width: 960px) {
    max-width: 1169px;
    justify-content: flex-start;
  }

  @media screen and (min-width: 1024px) {
    .image-component {
      margin-right: 130px;
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 30px 0px 58px;
    column-gap: 170px;

    .banner-content {

      .banner-title,
      .banner-text {
        margin-bottom: 28px;
      }

      .banner-text {
        width: 400px;
      }
    }

    .image-component img {
      width: 434px;
      height: 464px;
    }
  }
}
</style>